<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item label="活动分类：" prop="cate_id">
        <el-select v-model="ruleForm.cate_id" placeholder="请选择" class="classify_select">
          <el-option v-for="item in cateInfo.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动标题：" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="活动价格：" prop="price">
        <el-input v-model="ruleForm.price" type="number"></el-input>
        <span>元/人</span>
      </el-form-item>
      <el-form-item label="地址定位：" prop="address">
        <!-- 按范围 自定义 -->
        <div class="locationBox">
          <el-input disabled v-model="ruleForm.longitude"></el-input>
          <el-input disabled v-model="ruleForm.latitude"></el-input>
          <el-button type="primary" @click="locationFlag = true">定位</el-button>
        </div>
      </el-form-item>
      <el-form-item label="活动地址：" prop="address">
        <el-input v-model="ruleForm.address" disabled></el-input>
      </el-form-item>
      <el-form-item label="活动海报：" prop="image">
        <ReadyUploadSource @changeOrder="changeOrder" isMany @getSource="getPost" @removeThis="removeImage" :manyPath="ruleForm.image"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="背景音乐：" prop="audio">
        <ReadyUploadSource type="file" @getSource="data => (ruleForm.audio = data.path)" @removeThis="ruleForm.audio = ''" :path="ruleForm.audio"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="活动城市：" prop="area_obj">
        <el-cascader v-model="ruleForm.area_obj" :options="allAreaList" :props="{ expandTrigger: 'hover', children: '_child', label: 'name', value: 'id' }"></el-cascader>
      </el-form-item>
      <el-form-item label="活动时间：" prop="time">
        <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="活动收集表单：" prop="form_id">
        <el-select v-more="loadMoreTypeFrom" v-model="ruleForm.form_id" filterable remote :remote-method="getFormList" placeholder="请选择" class="classify_select">
          <el-option v-for="item in formInfo.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="表单顶部背景图：" prop="form_id">
        <ReadyUploadSource :path="ruleForm.noun" @getSource="data => (ruleForm.noun = data.path)" @removeThis="ruleForm.noun = ''"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="表单背景色：" prop="form_id">
        <div class="content">
          <el-color-picker v-model="ruleForm.back_color"></el-color-picker>
          <el-button type="primary" @click="ruleForm.back_color = ''">重置</el-button>
        </div>
      </el-form-item>
      <el-form-item label="活动详情：" prop="detail">
        <RichText :richTxt="ruleForm.detail" @soninfo="val => (ruleForm.detail = val)"></RichText>
      </el-form-item>
    </el-form>
    <Preservation v-if="!look_id" @preservation="Preservation"></Preservation>
    <!-- 打开高德地图进行定位 -->
    <el-dialog title="定位" :visible.sync="locationFlag" width="1000px">
      <GaoDe
        v-if="locationFlag"
        :initialization="locationFlag"
        ref="searchGaode"
        id="searchId"
        :FunctionMode="2"
        :options="{ center: [ruleForm.longitude, ruleForm.latitude], keyword: ruleForm.address }"
      ></GaoDe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="locationFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureAreaInfo">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import GaoDe from '@/components/gaode.vue';
import _ from 'lodash';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
export default {
  components: {
    Preservation,
    ReadyUploadSource,
    RichText,
    GaoDe,
  },
  data() {
    function checkArea(rule, value, callback) {
      if (!value || !value.length) {
        callback(new Error('请选择城市'));
      } else {
        callback();
      }
    }
    function checkTime(rule, value, callback) {
      if (!value || !value.length) {
        callback(new Error('请选择城市'));
      } else {
        callback();
      }
    }
    return {
      // 定位弹框
      locationFlag: false,
      look_id: null,
      edit_id: null,
      ruleForm: {
        sort: 99,
        cate_id: '',
        audio: '',
        longitude: '',
        latitude: '',
        title: '',
        price: '',
        area_obj: [],
        address: '',
        time: [],
        form_id: null,
        image: [],
        detail: '',
        back_color: '',
        noun: '',
      },
      rules: {
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        cate_id: [{ required: true, message: '请选择活动分类', trigger: 'blur' }],
        form_id: [{ required: true, message: '请选择活动表单', trigger: 'blur' }],
        title: [{ required: true, message: '请输入活动标题', trigger: 'blur' }],
        price: [{ required: true, message: '请输入活动价格', trigger: 'blur' }],
        area_obj: [{ validator: checkArea, trigger: 'blur' }],
        time: [{ validator: checkTime, trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        image: [{ required: true, message: '请选择图片', trigger: 'blur' }],
        detail: [{ required: true, message: '请输入活动详情', trigger: 'blur' }],
      },
      allAreaList: [],
      cateInfo: {
        list: [],
      },
      formInfo: {
        name: '',
        page: 1,
        rows: 10,
        total: 0,
        list: [],
      },
    };
  },
  // 自定义指令
  directives: {
    more: {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  created() {
    if (this.$route.query.look_id) {
      this.look_id = this.$route.query.look_id;
      this.getInfo(this.look_id);
    }
    if (this.$route.query.edit_id) {
      this.edit_id = this.$route.query.edit_id;
      this.getInfo(this.edit_id);
    }
    this.getArea();
    this.getCateList();
    this.getFormList();
  },
  methods: {
    //
    sureAreaInfo() {
      let { defaultSearch, defaultCenter } = this.$refs.searchGaode;
      this.ruleForm.longitude = defaultCenter[0];
      this.ruleForm.latitude = defaultCenter[1];
      this.ruleForm.address = defaultSearch;
      this.locationFlag = false;
    },
    changeOrder(list) {
      this.ruleForm.image = list;
    },
    getPost(list) {
      list.map(item => this.ruleForm.image.push(item.path));
    },
    removeImage(index) {
      this.ruleForm.image.splice(index, 1);
    },
    getInfo(id) {
      this.$axios.post(this.$api.gateway.activity.infoActivity, { id }).then(res => {
        if (res.code == 0) {
          res.result.area_obj = [res.result.province_id, res.result.city_id];
          res.result.image = res.result.images;
          res.result.time = [new Date(res.result.start_time * 1000), new Date(res.result.end_time * 1000)];
          for (const key in this.ruleForm) {
            this.ruleForm[key] = res.result[key];
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取省市区信息
    getArea() {
      this.$axios
        .get(this.$api.shopDiy.areaList, {
          tree: 1,
          level: 1,
        })
        .then(res => {
          if (res.code == 0) {
            this.allAreaList = res.result.list;
          }
        });
    },
    getCateList(name) {
      let obj = {
        is_show: 2,
      };
      this.$axios.post(industryName + this.$api.goods.goodsClass, obj).then(res => {
        if (res.code === 0) {
          this.cateInfo.list = res.result || [];
        }
      });
    },
    loadMoreTypeFrom() {
      if (Math.ceil(this.formInfo.total / this.formInfo.rows) > this.formInfo.page) {
        this.formInfo.page++;
        this.getFormList(this.formInfo.name, 1);
      }
    },
    getFormList(name, is_more) {
      if (!is_more) {
        this.formInfo.page = 1;
      }
      this.formInfo.name = name;
      this.$axios
        .post(this.$api.customform.list, {
          page: this.formInfo.page,
          rows: this.formInfo.rows,
          name,
        })
        .then(res => {
          if (res.code === 0) {
            if (is_more) {
              this.formInfo.list = this.formInfo.list.concat(res.result.list);
            } else {
              this.formInfo.list = res.result.list || [];
            }
            this.formInfo.total = res.result.total_number;
          }
        });
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          delete obj.area_obj;
          delete obj.time;
          obj.province_id = this.ruleForm.area_obj[0];
          obj.city_id = this.ruleForm.area_obj[1];
          if (this.ruleForm.time?.length) {
            obj.start_time = Math.ceil(this.ruleForm.time[0].getTime() / 1000);
            obj.end_time = Math.ceil(this.ruleForm.time[1].getTime() / 1000);
            obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time;
          }
          let path = this.$api.gateway.activity.addActivity;
          let str = '添加成功';
          if (this.edit_id) {
            path = this.$api.gateway.activity.editActivity;
            obj.id = this.edit_id;
            str = '编辑成功';
          }
          this.$axios.post(path, obj).then(res => {
            if (res.code == 0) {
              this.$message.success(str);
              this.$router.push('/extension/gatewayActivity/listActivity');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  padding-bottom: 80px;
  height: 100%;

  .el-form {
    .el-input {
      width: 700px;
    }
  }
  .locationBox {
    display: flex;
    .el-input {
      width: 165px !important;
    }
  }
  .content {
    width: 400px;
    display: flex;
    align-items: center;
  }
  .el-color-picker,
  .el-select {
    flex: 1;
    margin-right: 16px;
    & /deep/.el-color-picker__trigger {
      width: 100%;
    }
  }
}
</style>
